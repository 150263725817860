
import { Component, Prop, Vue } from 'vue-property-decorator';
import OfferCard from '@/components/offers/OfferCard.vue';
import PromptMessage from '@/components/offers/PromptMessage.vue';

// Config Variables
import { ActiveTenant } from '../../../active-tenant';
import { tenants } from '../../../tenant-config';
import { getLayoutData } from '@/utils/helperFunctions';
import { FeatureModule, UserModule } from '@/store/modules';
import { IAuthUser } from '@/types/user.types';
import { ICauseSettings } from '@/types/cause.types';
import { FeatureService } from '@/services';

@Component({
  components: { OfferCard, PromptMessage }
})
export default class MainHeader extends Vue {
  @Prop({ default: false })
  isOnboard!: boolean;
  background: string | null = '';
  overlay = '';
  banner = '';
  loading = false;
  activeTenant = tenants[ActiveTenant];
  headerBarBackground = '';
  textColor = '';

  get headerImageStyle(): any {
    return {
      '--darken': '0.4',
      height: 'var(--image-height)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '40px',
      // // NOTE: This color should NEVER be present/visible!
      backgroundColor: this.headerBarBackground,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url('${this.background}')`
    };
  }
  get user(): IAuthUser | null {
    return UserModule.user;
  }

  get currentCause(): ICauseSettings | undefined {
    return this.user?.cause;
  }

  features = FeatureModule.getFeatureStatuses;
  isMultipleCauseEnabled = this.features.isMultipleCauseEnabled;
  isOffersEnabled = this.features.isOffersEnabled;

  getBackgroundImage(): void {
    const headerImages = getLayoutData();
    if (this.isMultipleCauseEnabled && this.currentCause) {
      this.background = this.isOnboard ? headerImages.onboardBackground : this.currentCause.logoUrl;
      this.overlay = this.isOnboard ? headerImages.onboardOverlay : (this.currentCause.overlayUrl as string);
      this.banner = this.isOnboard
        ? headerImages.onboardBanner
        : (this.currentCause.partnerLogoUrl as string);
      this.headerBarBackground = `#${this.currentCause?.primaryColor}`;
      this.textColor = `#${this.currentCause?.secondaryColor}`;
    } else {
      this.background = this.isOnboard ? headerImages.onboardBackground : headerImages.background;
      this.overlay = this.isOnboard ? headerImages.onboardOverlay : headerImages.overlay;
      this.banner = this.isOnboard ? headerImages.onboardBanner : headerImages.banner;
      this.headerBarBackground = this.activeTenant.appHeader.barColor;
      this.textColor = 'white';
    }
  }

  async mounted(): Promise<void> {
    this.getBackgroundImage();
    const response = await FeatureService.getIsMultipleCauseEnabled();
    this.isMultipleCauseEnabled = response.isMultipleCauseEnabled;
  }
}
