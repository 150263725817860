
import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/offers/Header.vue';

@Component({
  components: {
    Header
  }
})
export default class Suggestion extends Vue {
  show = true;
}
