
import { Component, Vue } from 'vue-property-decorator';
import OfferCard from '@/components/offers/OfferCard.vue';
import PromptMessage from '@/components/offers/PromptMessage.vue';
import AppHeaderImages from '@/components/offers/AppHeaderImages.vue';

// Config Variables
import { ActiveTenant } from '../../../active-tenant';
import { tenants } from '../../../tenant-config';
import { getLayoutData } from '@/utils/helperFunctions';

@Component({
  components: { OfferCard, PromptMessage, AppHeaderImages }
})
export default class MainHeader extends Vue {
  ActiveTenant = ActiveTenant;
  currentTenant = tenants[ActiveTenant];
  background: string | null = null;
  overlay: string | null = null;
  banner: string | null = null;
  loading = false;

  getBackgroundImage(): void {
    const headerImages = getLayoutData();
    this.background = headerImages.background;
    this.overlay = headerImages.overlay;
    this.banner = headerImages.banner;
  }

  mounted(): void {
    this.getBackgroundImage();
  }
}
