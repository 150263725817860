
import { AuthModule, UserModule } from '@/store/modules';
import { Component, Vue } from 'vue-property-decorator';

// Components
import { AuthService } from '@/services';

@Component
export default class PromptMessage extends Vue {
  created(): void {
    // this.setOfferDetails();
  }
  get isAuthenticated() {
    return AuthModule.isAuthenticated;
  }

  get isVerified() {
    return UserModule.verified;
  }

  get hasPayment() {
    return UserModule.hasPaymentMethod;
  }

  get show() {
    return !this.isAuthenticated || !this.isVerified || !this.hasPayment;
  }

  async onAccountVerification() {
    try {
      await AuthService.sendVerifyAccountEmail();
      this.$router.replace({
        path: '/account-verification'
      });
    } catch (e: any) {
      const message = this.$getError(
        e,
        'Could not send an email with an account verification code'
      );
      this.$notifyError(message, true);
    }
  }
}
